<template>
    <div class="head">
        <div class="weather">
            <!--
            <iframe scrolling="no" src="https://tianqiapi.com/api.php?style=tb&fontsize=16&skin=pitaya&color=fff" frameborder="0" width="350" height="24" allowtransparency="true"></iframe>
            -->
        </div>
        <div class="title">
            <img src="./../../assets/images/title.png" height="103" alt="">
            <div class="left_line"></div>
            <div class="right_line"></div>
        </div>
        <div class="date">
            <div class="left">{{nowTime}}</div>
            <div class="right">
                <p>农历{{getLunarDay.dateStr}}</p>
                <p>{{nowDate}}</p>
            </div>
            <!-- {{nowDate}} -->
        </div>
    </div>
</template>
<script>
import { getLunar } from 'chinese-lunar-calendar'
   export default{
       name:'Head',
       data(){
           return{
                nowDate: "", // 当前日期
                nowTime: "", // 当前时间
                getLunarDay: {},// 当前农历时间
                year: new Date().getFullYear(),
                month: new Date().getMonth() + 1,
                date: new Date().getDate()
           }
       },
       mounted(){
            // 获取农历
            this.getLunarDay = getLunar(this.year, this.month, this.date)
            // 获取当前时间 
            this.currentTime();
       },
       methods: {
            currentTime() {
                setInterval(this.formatDate, 500);
            },
            formatDate() {
                let date = new Date();
                let year = date.getFullYear(); // 年
                let month = date.getMonth() + 1; // 月
                month = month < 10 ? "0" + month : month; // 如果只有一位，则前面补零
                let day = date.getDate(); // 日
                day = day < 10 ? "0" + day : day; // 如果只有一位，则前面补零
                let week = date.getDay(); // 星期
                let weekArr = [ "星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六" ];
                let hour = date.getHours(); // 时
                hour = hour < 10 ? "0" + hour : hour; // 如果只有一位，则前面补零
                let minute = date.getMinutes(); // 分
                minute = minute < 10 ? "0" + minute : minute; // 如果只有一位，则前面补零
                let second = date.getSeconds(); // 秒
                second = second < 10 ? "0" + second : second; // 如果只有一位，则前面补零
                this.nowDate = `${year}-${month}-${day} ${weekArr[week]}`;
                this.nowTime = `${hour}:${minute}:${second}`;
            },
       }
   }
</script>
<style lang='scss' scoped>
   .head{
        color: #fff;
        position: relative;
        .title{
            text-align: center;
            width: 100%;
            // animation: menuCenterLight 3s linear 2s infinite normal both running;
            position: relative;
            .left_line{
                background: url('~@/assets/images/head_light_left.png') no-repeat center/100% 100%;
                width: 100px;
                height: 10px;
                background-size: 100% 100%;
                position: absolute;
                bottom: 0;
                left: 0vw;
                animation: moveLeft 1.5s linear .5s infinite normal both running;
            }
            .right_line{
                background: url('~@/assets/images/head_light_right.png') no-repeat center/100% 100%;
                width: 100px;
                height: 10px;
                background-size: 100% 100%;
                position: absolute;
                bottom: 0;
                right: 0vw;
                animation: moveRight 1.5s linear .5s infinite normal both running;
            }
        }
        @keyframes moveLeft {
            0% {
                left: 0vw;
                opacity: 0;
            }
            5% {
                left: 5vw;
                opacity: 1;
            }
            50% {
                left: 25vw;
                opacity: 1;
            }
            100% {
                left: 50vw;
                opacity: 0;
            }
        }
        @keyframes moveRight {
            0% {
                right: 0vw;
                opacity: 0;
            }
            5% {
                right: 5vw;
                opacity: 1;
            }
            50% {
                right: 25vw;
                opacity: 1;
            }
            100% {
                right: 50vw;
                opacity: 0;
            }
        }
        .weather{
            position: absolute;
            top: 50px;
            left: 100px;
        }
        .date{
            position: absolute;
            top: 50px;
            right: 115px;
            font-size: 34px;
            display: flex;
            align-items: center;
            .left{
                font-size: 34px;
            }
            .right{
                margin-left: 10px;
                p{
                    font-size: 14px;
                    span{
                        margin-left: 10px;
                    }
                }
            }
        }
   }
</style>