<template>
    <scale-box >
        <div class="index" :class="bgImg">
            <Head/>
            <div class="main">
                <router-view></router-view>
            </div>
            <nav-menu></nav-menu>
        </div>
    </scale-box >
</template>
<script>
    import ScaleBox from '@/components/scaleBox'
    import Head from '@/views/layout/head'
    import NavMenu from '@/views/layout/navMenu'
    import { destory } from '@/utils/video'
    import { spjcDestory } from '@/utils/video1'
   export default{
       name:'Index',
       components:{ScaleBox,Head,NavMenu},
       data(){
           return{
               bgImg:'bg',//
           }
       },
       created() {
           let token = window.sessionStorage.getItem('token', '')
           if (!token) {
                this.$router.push({
                    path: '/login'
                })
           }
       },
       watch:{
            $route: {
                deep: true,
                immediate: true,
                handler: function(val){
                    destory()
                    spjcDestory()
                  if (val.path=='/jsgc') {//技术规程
                    this.bgImg='jsgc_bg'
                  }else if(val.path=='/zjfw'){//专家服务
                      this.bgImg='zjfw_bg'
                  }else if(val.path=='/scjg'){//市场价格
                      this.bgImg='scjg_bg'
                  }else if(val.path=='/zhsc'||val.path=='/jpjs'){//智慧生产-精品建设
                      this.bgImg='zhsc_bg'
                  }else{
                      if (this.bgImg!='bg') {
                        this.bgImg='bg'
                      }
                  }
                }
            }
        },
       mounted(){
        //    console.log(this.$route.path)
       },
   }
</script>
<style lang='scss' scoped>
   .index{
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        .main{
            // border: 2px solid #f00;
            flex: 1;
            overflow: hidden;
            margin-bottom: 10px;
        }
   }
    .bg{
        background: url('~@/assets/images/bg.png') no-repeat center/100% 100%;
    }
    .jsgc_bg{
        background: url('~@/assets/images/jsgc_bg.png') no-repeat center/100% 100%;
    }
    .zjfw_bg{
        background: url('~@/assets/images/zjfw_bg.png') no-repeat center/100% 100%;
    }
    .scjg_bg{
        background: url('~@/assets/images/scjg_bg.png') no-repeat center/100% 100%;
    }
    .zhsc_bg{
        background: url('~@/assets/images/zhsc_bg.png') no-repeat center/100% 100%;
    }
</style>