<template>
    <div class="nav_menu">
        <ul>
            <li :class="{active:$route.path==item.url}" @click="goPage(item.url)" v-for="item in menuList" :key="item.name">{{item.name}}</li>
        </ul>
    </div>
</template>
<script>
   export default{
       name:'NavMenu',
       data(){
           return{
               menuList:[//菜单
                    {
                        name:'产业概况',
                        url:'/cygk'
                    },{
                        name:'产业分布',
                        url:'/cyfb'
                    },{
                        name:'市场价格',
                        url:'/scjg'
                    },{
                        name:'智慧生产',
                        url:'/zhsc'
                    },{
                        name:'技术规程',
                        url:'/jsgc'
                    },{
                        name:'专家服务',
                        url:'/zjfw'
                    },{
                        name:'产业服务',
                        url:'/cyfw'
                    },{
                        name:'产销对接',
                        url:'/cxdj'
                    },{
                        name:'精品建设',
                        url:'/jpjs'
                    }
               ]
           }
       },
       mounted(){
       },
       methods: {
           goPage(url){//跳转
                this.$router.push({
                    path:url
                })
           }
       }
   }
</script>
<style lang='scss' scoped>
   .nav_menu{
        width: 100%;
        // height: 100%;
        // border: 1px solid #0f0;
        height: 87px;
        width: 1457px;
        margin: 0 auto;
       ul{
           display: flex;
           align-items: center;
           justify-content: center;
           margin: 0 auto;
           height: 100%;
        //    border: 1px solid #f00;
           background: url('~@/assets/images/menu.png') no-repeat center/100% 100%;
           height: 100%;
           >li{
               width: 116px;
               height: 52px;
               text-align: center;
               line-height: 52px;
               font-weight: 400;
               cursor: pointer;
               font-size: 20px;
               color: #32EEFF;
               background: linear-gradient(180deg, rgba(31,98,175,1) ,rgba(31,98,175,0));
               margin: 0 2px;
           }
           >li:first-child{
               width: 191px;
                text-align: right;
                padding-right: 25px;
                margin-left: 0;
                box-sizing: border-box;
               background: url('~@/assets/images/btn_first.png') no-repeat center/100% 100%;
           }
           >li:first-child.active{
               color: #fff;
               background: url('~@/assets/images/btn_first_active.png') no-repeat center/100% 100%;
           }
           >li:last-child{
               width: 191px;
                text-align: left;
                padding-left: 25px;
                box-sizing: border-box;
                margin-right: 0;
               background: url('~@/assets/images/btn_last.png') no-repeat center/100% 100%;
           }
           >li:last-child.active{
               color: #fff;
               background: url('~@/assets/images/btn_last_active.png') no-repeat center/100% 100%;
           }
           .active{
               color: #fff;
               background-image: linear-gradient(180deg, rgba(213,157,84,1),rgba(213,157,84,0));
           }
       }
   }
</style>